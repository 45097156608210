import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import './TaskList.css';


function Task(props) {
  const [modalOpen, setModalOpen] = useState(false);

  const getColor = props.getColor;
  const task = props.task;

  return (
    <div>
      <Button 
        id="task-button"
        className="task-button"
        onClick={() => setModalOpen(true)}
        style={{
          backgroundColor: getColor(task.class_name, task.difficulty)
        }}
        variant="contained">
        <Grid container>
          <Grid item xs={3} align="left">
            {task.class_name}
          </Grid>
          <Grid item xs={6} align="left">
            {task.task_name}
          </Grid>
          <Grid item xs={3} align="right">
            {task.deadline.toLocaleDateString("en-US")}
          </Grid>
        </Grid>
      </Button>
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="task-modal"
      >
        <Box
          id="task-modal"
          sx={{
            backgroundColor: getColor(task.class_name, task.difficulty)
          }}
        >
          <h2>{task.task_name}</h2>
          <h3>{task.class_name}</h3>
          <h3>Due: {task.deadline.toLocaleDateString("en-US")}</h3>
          <p>{task.description}</p>
        </Box>
      </Modal>
    </div>
  );
}

export default Task;
