import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Paper from '@mui/material/Paper';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import './AddTask.css';


function importClassSettings(className, classes, setColor, setClassName) { 
  if (className in classes) 
    setColor(classes[className]["class_color"]);
  setClassName(className);
}

function submitTask(addTask, taskName, className, 
  deadline, difficulty, color, description, reset) {
  addTask(taskName, className, deadline, difficulty, color, description);
  reset();
}

function AddTask(props) {
  const [modalOpen, setModalOpen] = useState(false);

  const [taskName, setTaskName] = useState("");
  const [className, setClassName] = useState("");
  const [deadline, setDeadline] = useState(new Date('2019-06-12T00:00:00'))
  const [difficulty, setDifficulty] = useState("Medium")
  const [color, setColor] = useState("Red");
  const [description, setDescription] = useState("");

  const colors = props.colors;
  const difficultyColor = props.difficultyColor;
  const classes = props.classes;
  const difficultyOptions = ["Easy", "Medium", "Hard"]
  const getColor = props.getColor;
 
  function reset() {
    setTaskName("");
    setClassName("");
    setDeadline(new Date('2019-06-12T00:00:00'));
    setDifficulty("Medium");
    setColor("Red");
    setDescription("")
    setModalOpen(false);
  }

  return (
    <>
      {/**Add Task Button**/}
      <Button 
          id="add-task-button" 
          variant="outlined"
          onClick={() => setModalOpen(true)}>
          Click To Create A New Task!
      </Button>
      {/**Add Task Modal**/}
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box id="add-task-modal">
          <h2 align="center">Add New Task</h2>
          {/**Task Options**/}
          {/**Task Name**/}
          <TextField
            id="task-name" 
            label="Task Name"
            variant="standard"
            fullWidth
            onChange={(event) => setTaskName(event.target.value)}/>
          {/**Class Name**/}
          <Autocomplete
            id="class-name-autocomplete"
            freeSolo
            options={Object.keys(classes)}
            inputValue={className}
            onInputChange={(event, newClassName) => importClassSettings(
                       newClassName, classes, setColor, setClassName)}
            renderInput={(params) => 
              <TextField 
                 id="class-name"
                 label="Class Name"
                 variant="standard"
                 className="add-task-list"
                 {...params}
                 fullWidth />
            }
            PaperComponent={({ children }) => (
              <Paper style={{ background:"#282c34", color:"white" }}>
                {children}
              </Paper>
            )}
          />
          {/**Deadline**/}
          <LocalizationProvider 
            dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="Deadline"
              inputFormat="MM/dd/yyyy"
              value={deadline}
              onChange={(newDeadline) => setDeadline(newDeadline)}
              renderInput={(params) => 
                <TextField 
                 id="deadline"
                 variant="standard"
                 {...params}
                 fullWidth />
              }
            />
          </LocalizationProvider>
          {/**Difficulty**/}
          <Autocomplete
            disablePortal
            id="difficulty-autocomplete"
            options={difficultyOptions}
            value={difficulty}
            onChange={(event, newDifficulty) => (
              difficultyOptions.includes(newDifficulty) ? setDifficulty(newDifficulty) 
              : setDifficulty("Medium"))}
            renderInput={(params) => 
              <TextField 
                id="difficulty"
                label="Difficulty"
                variant="standard"
                className="add-task-list"
                {...params}
                fullWidth />
            }
            PaperComponent={({ children }) => (
              <Paper style={{ background:"#282c34", color:"white" }}>
                {children}
              </Paper>
            )}
          />
          {/**Optional Description**/}
          <TextField
            id="description"
            label="Description"
            multiline
            value={description}
            onChange={(event) => setDescription(event.target.value)}
            variant="standard"
            fullWidth />
          {/**Class Options**/}
          <h3>Class Options</h3>
          <Grid container>
            <Grid item xs={1}> 
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  backgroundColor: colors[color][500 + difficultyColor[difficulty]],
                }}
              />
            </Grid>
            <Grid item xs={1}/> 
            <Grid item xs={10}>
              <Autocomplete
                id="class-color-autocomplete"
                options={Object.keys(colors)}
                value={color}
                onChange={(event, newColor) => (
                  (newColor in colors) ? setColor(newColor) : setColor("Red"))}
                renderInput={(params) => 
                  <TextField 
                     id="class-color"
                     label="Class Color"
                     variant="standard"
                     className="add-task-list"
                     {...params}
                     fullWidth />
                }
                PaperComponent={({ children }) => (
                  <Paper style={{ background:"#282c34", color:"white" }}>
                    {children}
                  </Paper>
                )}
              />
            </Grid>
          </Grid>
          {/**Action Buttons**/}
          <Grid 
            className="add-task-action-buttons" 
            container>
            <Grid item xs={2} />
            <Grid
              item 
              xs={2}>
              <Button 
                fullWidth
                variant="contained"
                color="error"
                onClick={() => reset()}>
                Cancel
              </Button>
            </Grid>
            <Grid item xs={4} />
            <Grid 
              item 
              xs={2}>
              <Button
                fullWidth
                variant="contained"
                color="success"
                onClick={() => submitTask(props.addTask, taskName, 
                  className, deadline, difficulty, color, description, 
                  reset)}>
                Submit 
              </Button>
            </Grid>
            <Grid item xs={2} />
          </Grid>
        </Box>
      </Modal>
    </>
  );
}

export default AddTask;
