import Button from '@mui/material/Button';
import AddTask from './AddTask/AddTask';
import TaskList from './TaskList/TaskList';
import React, { useState } from 'react';
import {red, pink, purple, deepPurple,
        indigo, blue, lightBlue, cyan,
        teal, green, lightGreen, lime,
        yellow, amber, orange, deepOrange, 
        brown, grey, blueGrey} from '@mui/material/colors';
import './App.css';


const colors = {
  "Red": red,
  "Pink": pink,
  "Purple": purple,
  "Deep Purple": deepPurple,
  "Indigo": indigo,
  "Blue": blue,
  "Light Blue": lightBlue,
  "Cyan": cyan,
  "Green": green,
  "Light Green": lightGreen,
  "Lime": lime,
  "Yellow": yellow,
  "Amber": amber,
  "Orange": orange,
  "Deep Orange": deepOrange,
  "Brown": brown,
  "Grey": grey,
  "Blue Grey": blueGrey
}

const difficultyColor = {
  "Easy": -200,
  "Medium": 0,
  "Hard": 200
}

function loadData(setTasks, setClasses) {
  setTasks([])
  setClasses({
    "class_name": {
      class_color: "Purple"
     }
  })
}

function App() {
  const [tasks, setTasks] = useState([]);
  const [classes, setClasses] = useState({});
  const [runLoadTasks, setRunLoadTasks] = useState(true);

  if (runLoadTasks) {
    loadData(setTasks, setClasses);
    setRunLoadTasks(false);
  }

  function addTask(taskName, className,
    deadline, difficulty, color, description) {
    
    setClasses(Object.assign({},
      classes, {[className]: {"class_color": color}}));
    setTasks(tasks.concat([{
      "task_name": taskName,
      "class_name": className,
      "deadline": deadline,
      "difficulty": difficulty,
      "description": description
    }]));
  }

  const getColor = (class_name, difficulty) => (
    colors[classes[class_name]["class_color"]][500 + difficultyColor[difficulty]]
  );

  return (
    <div className="App">
        <h1> Uppsie's Study List </h1>
        <AddTask 
          colors={colors}
          addTask={addTask}
          difficultyColor={difficultyColor}
          classes={classes}/>
        <TaskList
          tasks={tasks}
          getColor={getColor}/>
    </div>
  );
}

export default App;
