import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Task from './Task';
import './TaskList.css';


function TaskList(props) {
  const tasks = props.tasks;
  const getColor = props.getColor;

  return (
    <>
      {tasks.map(task =>
        <Task
          task={task}
          getColor={getColor}
        />
      )}
    </>
  );
}

export default TaskList;
